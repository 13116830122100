type ProductType = {
  id: string;
  name: string;
};
export const productTypes: ProductType[] = [
  {
    id: "dh-lite",
    name: "Jobilla Target Lite",
  },
  {
    id: "dh",
    name: "Jobilla Target",
  },
  {
    id: "dh-pro",
    name: "Jobilla Target Pro",
  },
  {
    id: "cc",
    name: "Candidate Contacting",
  },
];
