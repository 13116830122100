import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, json, redirect, RouteObject, RouterProvider } from "react-router-dom";
import { OverlayProvider } from "@react-aria/overlays";
import * as Sentry from "@sentry/react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { init as initSentry } from "@/services/sentry";
import { ConditionalPostHogProvider } from "@/components/posthog";

import * as CompanyListPage from "@/routes/companies/route";
import * as CompanyCreatePage from "@/routes/companies.create/route";
import * as CompanyDetailsPage from "@/routes/companies.$company/route";
import { loader as CompanyOrdersApi } from "@/routes/companies.$company.orders/route";
import * as CompanyDetailsNameDialog from "@/routes/companies.$company.name/route";
import * as CompanyDetailsHubspotDialog from "@/routes/companies.$company.hubspot-link/route";
import * as CompanyDetailsHubspotConfirmDialog from "@/routes/companies.$company.hubspot-link.$confirm/route";
import * as CompanyDetailsAddBillingDialog from "@/routes/companies.$company.billings.new/route";
import * as CompanyDetailsEditBillingDialog from "@/routes/companies.$company.billings.$billing/route";
import * as CompanyDetailsNotesDialog from "@/routes/companies.$company.note/route";
import * as InvoicesListPage from "@/routes/invoices/route";
import * as InvoiceDetailsPage from "@/routes/invoices.$invoice/route";
import * as InvoiceDetailsPayeeDialog from "@/routes/invoices.$invoice.payee/route";
import * as InvoiceDetailsLatestDateDialog from "@/routes/invoices.$invoice.latest-date/route";
import * as InvoiceDetailsPaymentTermDialog from "@/routes/invoices.$invoice.payment-term/route";
import * as InvoiceDetailsRequestBillingDialog from "@/routes/invoices.$invoice.request-billing/route";
import * as InvoiceDetailsSelectBillingDialog from "@/routes/invoices.$invoice.select-billing/route";
import { action as InvoiceStatusUpdateAction } from "@/routes/invoices.$invoice.update-status/route";
import { action as InvoiceSendNetsuiteAction } from "@/routes/invoices.$invoice.send-netsuite/route";
import * as InvoiceCreateNoteApi from "@/routes/invoices.$invoice.create-note/route";
import * as OrdersListPage from "@/routes/orders/route";
import * as OrderDetailsHubspotDialog from "@/routes/orders.$order.hubspot/route";
import * as OrderDetailsPage from "@/routes/orders.$order/route";
import * as CancelOrderDialog from "@/routes/orders.$order.cancel/route";
import * as OrderSalesSplitDialog from "@/routes/orders.$order.sales-split/route";
import { action as OrderItemPriceUpdateAction } from "@/routes/orders.$order.item-price/route";
import { action as OrderItemStatusUpdateAction } from "@/routes/order-items.$orderItem.update-status/route";
import * as CampaignRequestPage from "@/routes/order-items.$orderItem.campaign-request/route";
import * as RecruitmentWizardPage from "@/routes/order-items.$orderItem.recruitment/route";
import * as OrderPipelinesPage from "@/routes/order-pipeline.$product/route";
import * as OrderItemPage from "@/routes/order-items.$orderItem/route";
import * as OrderItemSplitDialog from "@/routes/order-items.$orderItem.split/route";
import * as OrderItemEditDetailsDialog from "@/routes/order-items.$orderItem.edit-details/route";
import * as OrderItemRevokeDialog from "@/routes/order-items.$orderItem.revoke/route";
import { action as InvoiceNoteAction } from "@/routes/invoice-notes.$note/route";
import { action as BillingCreateApi } from "@/routes/api.billings.create/route";
import { action as BillingUpdateApi } from "@/routes/api.billings.$billing/route";
import * as OrderNewPage from "@/routes/orders.create/route";
import * as UnitPage from "@/routes/units.$unit/route";
import * as UnitPageInviteUserDialog from "@/routes/units.$unit.invite/route";
import * as ApiInviteResend from "@/routes/api.invites.$user.resend/route";
import * as ApiInviteCancel from "@/routes/api.invites.$user.cancel/route";
import * as ApiUserUpdateUnit from "@/routes/api.users.$user.unit/route";
import * as ApiUserUpdateRole from "@/routes/api.users.$user.role/route";
import * as OrderItemDialog from "@/routes/order-pipeline.$product.details.$orderItem/route";
import * as Root from "./root";

import "./index.css";

const processImportedRoute = (
  route: RouteObject & { default?: React.ComponentType },
): RouteObject => {
  return {
    ...route,
    element: route.element ?? (route.default ? <route.default /> : undefined),
  };
};

initSentry();

const createBrowserRouterWithSentry = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = createBrowserRouterWithSentry([
  {
    path: "/",
    id: "root",
    ...processImportedRoute(Root),
    children: [
      {
        index: true,
        loader: () => redirect("/companies"),
      },
      {
        path: "/companies",
        ...processImportedRoute(CompanyListPage),
      },
      {
        path: "/companies/create",
        ...processImportedRoute(CompanyCreatePage),
      },
      {
        path: "/companies/:company",
        ...processImportedRoute(CompanyDetailsPage),
        id: "company-details",
        index: false,
        children: [
          {
            path: "name",
            ...processImportedRoute(CompanyDetailsNameDialog),
          },
          {
            path: "hubspot-link",
            ...processImportedRoute(CompanyDetailsHubspotDialog),
          },
          {
            path: "hubspot-link/:confirm",
            ...processImportedRoute(CompanyDetailsHubspotConfirmDialog),
          },
          {
            path: "billings/new",
            ...processImportedRoute(CompanyDetailsAddBillingDialog),
          },
          {
            path: "billings/:billing",
            ...processImportedRoute(CompanyDetailsEditBillingDialog),
          },
          {
            path: "orders",
            loader: CompanyOrdersApi,
          },
          {
            path: "notes",
            ...processImportedRoute(CompanyDetailsNotesDialog),
          },
        ],
      },
      {
        path: "/invoices",
        ...processImportedRoute(InvoicesListPage),
      },
      {
        id: "invoice-details",
        path: "/invoices/:invoice",
        ...processImportedRoute(InvoiceDetailsPage),
        children: [
          {
            path: "create-note",
            ...processImportedRoute(InvoiceCreateNoteApi),
          },
          {
            path: "payee",
            ...processImportedRoute(InvoiceDetailsPayeeDialog),
          },
          {
            path: "latest-date",
            ...processImportedRoute(InvoiceDetailsLatestDateDialog),
          },
          {
            path: "payment-term",
            ...processImportedRoute(InvoiceDetailsPaymentTermDialog),
          },
          {
            path: "select-billing",
            ...processImportedRoute(InvoiceDetailsSelectBillingDialog),
          },
          {
            path: "update-status",
            action: InvoiceStatusUpdateAction,
          },
          {
            path: "send-netsuite",
            action: InvoiceSendNetsuiteAction,
          },
          {
            path: "request-billing",
            ...processImportedRoute(InvoiceDetailsRequestBillingDialog),
          },
        ],
      },
      {
        path: "/invoice-notes/:note",
        action: InvoiceNoteAction,
      },
      {
        path: "/orders",
        ...processImportedRoute(OrdersListPage),
      },
      {
        path: "/orders/create",
        ...processImportedRoute(OrderNewPage),
      },
      {
        id: "order-pipeline",
        path: "/order-pipeline/:product",
        ...processImportedRoute(OrderPipelinesPage),
        children: [
          {
            path: "details/:orderItem",
            ...processImportedRoute(OrderItemDialog),
          },
        ],
      },
      {
        path: "/units/:unit",
        ...processImportedRoute(UnitPage),
        children: [
          {
            path: "invite",
            ...processImportedRoute(UnitPageInviteUserDialog),
          },
        ],
      },
      {
        path: "/orders/:order",
        ...processImportedRoute(OrderDetailsPage),
        id: "order-details",
        index: false,
        children: [
          {
            path: "cancel",
            ...processImportedRoute(CancelOrderDialog),
          },
          {
            path: "sales-split",
            ...processImportedRoute(OrderSalesSplitDialog),
          },
          {
            path: "hubspot",
            ...processImportedRoute(OrderDetailsHubspotDialog),
          },
          {
            path: "item-price",
            action: OrderItemPriceUpdateAction,
          },
        ],
      },
      {
        id: "order-item-details",
        path: "/order-items/:orderItem",
        ...processImportedRoute(OrderItemPage),
        children: [
          {
            path: "revoke",
            ...processImportedRoute(OrderItemRevokeDialog),
          },
          {
            path: "edit-details",
            ...processImportedRoute(OrderItemEditDetailsDialog),
          },
          {
            path: "split",
            ...processImportedRoute(OrderItemSplitDialog),
          },
        ],
      },
      {
        id: "order-item-update-status",
        path: "/order-items/:orderItem/status",
        action: OrderItemStatusUpdateAction,
      },
      {
        id: "campaign-request",
        path: "order-items/:orderItem/campaign-request",
        ...processImportedRoute(CampaignRequestPage),
      },
      {
        id: "recruitment-wizard",
        path: "order-items/:orderItem/recruitment",
        ...processImportedRoute(RecruitmentWizardPage),
      },
    ],
  },
  {
    path: "api",
    children: [
      {
        path: "billings/create",
        action: BillingCreateApi,
      },
      {
        path: "billings/:billing",
        action: BillingUpdateApi,
      },
      {
        path: "invites/:user/resend",
        ...processImportedRoute(ApiInviteResend),
      },
      {
        path: "invites/:user/cancel",
        ...processImportedRoute(ApiInviteCancel),
      },
      {
        path: "users/:user/unit",
        ...processImportedRoute(ApiUserUpdateUnit),
      },
      {
        path: "users/:user/role",
        ...processImportedRoute(ApiUserUpdateRole),
      },
    ],
  },
  {
    path: "*",
    element: <div />,
    loader: () => {
      throw json({}, { status: 404 });
    },
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <OverlayProvider>
      <TooltipProvider delayDuration={300}>
        <ConditionalPostHogProvider>
          <RouterProvider router={router} />
        </ConditionalPostHogProvider>
      </TooltipProvider>
    </OverlayProvider>
  </React.StrictMode>,
);
