import { useLoaderData } from "react-router-dom";
import { useMemo } from "react";
import { orderBy } from "lodash";
import { parseISO } from "date-fns";
import * as DL from "@/components/definition-list";
import StyledLink from "@/components/styled-link";
import * as Timeline from "@/components/timeline";
import Date, { DateDisplayType } from "@/components/date";
import Section from "@/components/section";
import { MoneyDisplay } from "@/components/money-display";
import { snakeCaseToName } from "@/helpers/snake-case-to-name";
import { OrderItemDialogData } from "./route";

export default function OrderItemDetails() {
  const { orderItem } = useLoaderData() as OrderItemDialogData;

  const historicStatusesSortedByDate = useMemo(
    () =>
      orderBy(orderItem.historicStatuses, (status) => parseISO(status.createdAt).getTime(), "asc"),
    [orderItem.historicStatuses],
  );

  return (
    <Section title="Details">
      <DL.Root>
        <DL.Item label="Customer">
          <StyledLink to={`/companies/${orderItem.order?.company?.id}`}>
            #{orderItem.order?.company?.id}
          </StyledLink>
        </DL.Item>

        <DL.Item label="Order">
          <StyledLink to={`/orders/${orderItem.order?.id}`}>
            #{orderItem.order?.numericId}
          </StyledLink>
        </DL.Item>

        <DL.Item label="Order item">#{orderItem.numericId}</DL.Item>
        <DL.Item label="Product">{orderItem.product?.title ?? <em>Deleted product</em>}</DL.Item>
        <DL.Item label="Price">
          <MoneyDisplay amount={orderItem.amount} />
        </DL.Item>

        <DL.Item label="Status history">
          <Timeline.Root>
            {historicStatusesSortedByDate.map((item, index) => (
              <Timeline.Item
                key={item.createdAt}
                icon={
                  index === orderItem.historicStatuses.length - 1
                    ? Timeline.ItemIcon.BlueDot
                    : Timeline.ItemIcon.GrayDot
                }
                size={
                  index === orderItem.historicStatuses.length - 1
                    ? Timeline.ItemIconSize.Big
                    : Timeline.ItemIconSize.Regular
                }
              >
                <Timeline.Label>{snakeCaseToName(item.status)}</Timeline.Label>
                <Timeline.Subtext>
                  <Date date={item.createdAt} display={DateDisplayType.DATE} />
                </Timeline.Subtext>
              </Timeline.Item>
            ))}
          </Timeline.Root>
        </DL.Item>
      </DL.Root>
    </Section>
  );
}
