import * as Sentry from "@sentry/react";
import { HTTPError } from "ky";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { ActionFunctionArgs, LoaderFunctionArgs, redirect, useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import { fetchOrder } from "@/api/order";
import { fetchOrderItem, splitOrderItem } from "@/api/order-item";
import { Dialog } from "@/components/dialog";
import { SpinnerIcon } from "@/components/icons/spinner";
import SecondaryButton from "@/components/secondary-button";
import SubmitButton from "@/components/submit-button";
import { assertParameterExists } from "@/helpers/loader-guards";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";
import { Table, TableColumn, TableRow } from "@/components/table";
import { OrderItem } from "@/entities/order-item";
import { MoneyDisplay } from "@/components/money-display";
import { processYupErrors } from "@/helpers/yup-errors";
import { fetchUserPermissionFor } from "@/api/permissions";

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);
  const [order, hasPermission] = await Promise.all([
    fetchOrder(orderItem.orderId),
    fetchUserPermissionFor("update", "order", orderItem.orderId),
  ]);

  const invoice = [...order.invoices]
    .reverse()
    .find((invoice) =>
      invoice.items.find((invoiceItem) => invoiceItem.orderItemId === orderItem.id),
    );

  if (
    !orderItem.isSplitEnabled(hasPermission, invoice)
  ) {
    return redirect(`/order-items/${orderItem.id}`);
  }

  if (!orderItem.order) {
    Sentry.captureMessage("Order item data does not include order entity", {
      level: "error",
      extra: {
        order_item_id: params.orderItem,
      },
    });

    orderItem.order = order;
  }

  return {
    orderItem,
    order,
  };
}

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);
  let input;
  try {
    const inputEntries = Object.fromEntries((await request.formData()).entries());
    input = await Yup.object()
      .shape({
        orderId: Yup.string().required(),
      })
      .validate(inputEntries, { abortEarly: false, stripUnknown: true });
  } catch ( e ) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        input: input,
        errors: processYupErrors(e),
      };
    }
    throw e;
  }

  try {
    await splitOrderItem(params.orderItem);
    return redirect(`/orders/${input.orderId}`);
  } catch ( e ) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors as Record<string, string[]>,
      };
    }
    return { status: 500 };
  }
}

export default function OrderItemSplitDialog() {
  const { orderItem, order } = useLoaderData() as OrderItemDetailsData;
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const posthog = usePostHog();

  const close = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/orders/${order?.id}`);
    }
  }, [navigate, order?.id]);

  useEffect(() => {
    if (fetcher.state === "submitting") {
      posthog.capture("target campaign split", {
        order_item_id: orderItem.id,
        order_item_numeric_id: orderItem.numericId,
      });
    }
  }, [fetcher.state]);

  return (
    <Dialog
      title={`Are you sure you want to split order item #${orderItem.numericId}?`}
      isOpen
      onClose={close}
      description="
      This will convert the existing target campaign into two of smaller size and different type.
      Separate invoices will be created for each of the new items.
      "
    >
      <fetcher.Form
        method="post"
        noValidate
        className="flex flex-col gap-y-4"
        action={`/order-items/${orderItem.id}/split?order=${order.id}`}
      >
        <input type="hidden" name="orderId" value={order.id} />
        <SplitOrderItemsPreview
          orderItem={orderItem}
          newProductTitle="Jobilla Target Lite"
          quantity={2}
        />

        <div className="mt-8 flex items-center justify-center space-x-4">
          <p
            className="text-sm text-center py-2 px-2 text-gray-900 italic bg-yellow-100 rounded-xl">
            This is a permanent action and cannot be undone.
            Are you sure you want to proceed?
          </p>
          <SubmitButton
            label="Confirm"
            size="lg"
            labelWhenSubmitting={<SpinnerIcon className="animate-spin h-4 w-4 text-white" />}
          />
          <SecondaryButton type="button" onClick={close} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </fetcher.Form>
    </Dialog>
  );
}

function SplitOrderItemsPreview({ orderItem, newProductTitle, quantity }: {
  orderItem: OrderItem, newProductTitle: string, quantity: number
}) {
  return (
    <Table columns={[
      { label: "Product" },
      { label: "Price" },
    ]}>
      <tbody>
      {Array.from({ length: quantity }).map((_, index) => (
        <TableRow key={index}>
          <TableColumn>
            {newProductTitle}
          </TableColumn>
          <TableColumn>
            <MoneyDisplay amount={orderItem.amount.divide(quantity)} />
          </TableColumn>
        </TableRow>
      ))}
      </tbody>
      <tfoot>
      <tr className="border-t">
        <td className="p-4 font-bold">
          {quantity} x {newProductTitle}
        </td>

        <td className="p-4 font-bold">
          <MoneyDisplay amount={orderItem.amount} />
        </td>
      </tr>
      </tfoot>
    </Table>
  );
}
