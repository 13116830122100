import { Suspense } from "react";
import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import StyledLink from "@/components/styled-link";
import { InvoiceItem } from "@/entities/invoice-item";
import { Product } from "@/entities/product";
import { Job } from "@/entities/job";
import { OrderItem } from "@/entities/order-item";
import { Table, TableColumn, TableRow } from "@/components/table";
import { MoneyDisplay } from "@/components/money-display";
import { InvoiceStatus } from "@/entities/invoice-status";
import { InvoiceDetailsData } from "./loader";

export function InvoiceItems() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;

  return (
    <Table
      columns={[
        { label: "Item #" },
        { label: "Product" },
        { label: "Status" },
        { label: "Amount" },
      ]}
    >
      <tbody>
        {invoice.items.map((item) => (
          <InvoiceItemRow key={item.id} item={item} />
        ))}
      </tbody>

      {invoice.items.length && (
        <tfoot>
          <tr className="border-t">
            <td colSpan={3} className="p-4 font-bold text-right">
              Total
            </td>

            <td className="p-4 text-right">
              <MoneyDisplay amount={invoice.amount} />
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}

function InvoiceItemRow({ item }: { item: InvoiceItem }) {
  const { orderItems, products, invoice } = useLoaderData() as InvoiceDetailsData;
  const relatedJobs = useAsyncValue() as Job[];

  const product = products.find((product: Product) => product.id === item.productId);
  const orderItem = orderItems.find((orderItem: OrderItem) => orderItem.id === item.orderItemId);

  return (
    <TableRow className="bg-white hover:bg-indigo-50">
      <TableColumn>
        {product?.isProductWithToken ? (
          <StyledLink className="font-bold" to={`/order-items/${item.orderItemId}`}>
            #{orderItem?.numericId}
          </StyledLink>
        ) : (
          <span className="font-bold text-gray-400">#{orderItem?.numericId}</span>
        )}
      </TableColumn>

      <TableColumn>
        {product?.title}
        {invoice.status === InvoiceStatus.Void &&
          " - distributed to " + (invoice?.transferDetails?.toCompany?.name ?? "related company")}
        {invoice.status !== InvoiceStatus.Void && product?.isProductWithToken && (
          <p className="italic">
            <Suspense
              key={item.id}
              fallback={
                <p>
                  <em>Loading invoice job and location...</em>
                </p>
              }
            >
              <Await resolve={relatedJobs}>
                <CampaignDetails orderItem={orderItem} />
              </Await>
            </Suspense>
          </p>
        )}
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center">
          {invoice.status !== InvoiceStatus.Void ? orderItem?.status : "-"}
        </div>
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
          <MoneyDisplay amount={item.amount} />
        </div>
      </TableColumn>
    </TableRow>
  );
}

function CampaignDetails({ orderItem }: { orderItem: OrderItem | undefined }) {
  const relatedJobs = useAsyncValue() as Job[];
  const relatedJob = relatedJobs?.find(
    (job: Job) => job?.id === orderItem?.campaignContext?.jobId?.toString(),
  );
  const name = relatedJob?.name ?? orderItem?.campaignContext?.jobName ?? null;

  const location = relatedJob
    ? relatedJob.location ?? "Anywhere"
    : orderItem?.campaignContext?.jobLocation ?? null;

  return (
    <span>
      {name ?? "No campaign name available"}, {location ?? "no location available"}
    </span>
  );
}
